@font-face {
	font-family: 'Mouse Memoirs';
	src: url('./assets/MouseMemoirs-Regular.woff2') format('woff2'), url('./assets/MouseMemoirs-Regular.woff') format('woff'), url('./assets/MouseMemoirs-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: fallback;
}
@font-face {
	font-family: 'Do Hyeon';
	src: url('./assets/DoHyeon-Regular.woff2') format('woff2'), url('./assets/DoHyeon-Regular.woff') format('woff'), url('./assets/DoHyeon-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: fallback;
}

@font-face {
	font-family: 'Yanone Kaffeesatz';
	src: url('./assets/YanoneKaffeesatz-Light.woff2') format('woff2'), url('./assets/YanoneKaffeesatz-Light.woff') format('woff'), url('./assets/YanoneKaffeesatz-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'Yanone Kaffeesatz';
	src: url('./assets/YanoneKaffeesatz-Regular.woff2') format('woff2'), url('./assets/YanoneKaffeesatz-Regular.woff') format('woff'), url('./assets/YanoneKaffeesatz-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'Yanone Kaffeesatz';
	src: url('./assets/YanoneKaffeesatz-Bold.woff2') format('woff2'), url('./assets/YanoneKaffeesatz-Bold.woff') format('woff'), url('./assets/YanoneKaffeesatz-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'loot-i';
	src: url('./assets/loot-i.eot?96663969');
	src: url('./assets/loot-i.eot?96663969#iefix') format('embedded-opentype'),
		 url('./assets/loot-i.woff2?96663969') format('woff2'),
		 url('./assets/loot-i.woff?96663969') format('woff'),
		 url('./assets/loot-i.ttf?96663969') format('truetype'),
		 url('./assets/loot-i.svg?96663969#loot-i') format('svg');
	font-weight: normal;
	font-style: normal;
}
  /* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
  /* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
  /*
  @media screen and (-webkit-min-device-pixel-ratio:0) {
	@font-face {
	  font-family: 'loot-i';
	  src: url('./assets/loot-i.svg?68949503#loot-i') format('svg');
	}
  }
  */
  [class^="loot-"]:before, [class*=" loot-"]:before {
	font-family: "loot-i";
	font-style: normal;
	font-weight: normal;
  
	display: inline-block;
	text-decoration: inherit;
	width: 1em;
	margin-right: .2em;
	text-align: center;
	/* opacity: .8; */
  
	/* For safety - reset parent styles, that can break glyph codes*/
	font-variant: normal;
	text-transform: none;
  
	/* fix buttons height, for twitter bootstrap */
	line-height: 1em;
  
  
	/* you can be more comfortable with increased icons size */
	/* font-size: 120%; */
  
	/* Font smoothing. That was taken from TWBS */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

  }